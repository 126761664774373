import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo";
import MainNav from "./navbar";
import {Col, Row, ListGroup} from "react-bootstrap";
import "./header.css";

const Header = ({ siteTitle, state }) => (
  <>
  <header>
    <div className="citiesTopNav">

      <Row className="citiesContent">
        <ListGroup horizontal className="groupList" >
          <ListGroup.Item className="listGroupItem"><a className="listGroupItem" target="_blank" href="/mommys-helper/">Mommy’s Helper – College Students Who Help Mommy</a></ListGroup.Item>
          {/*<span className="verticalLine"> | </span>*/}
          {/*<ListGroup.Item className="listGroupItem"><a className="listGroupItem" target="_blank" href="https://www.eventbrite.com/e/moms-on-maternity-match-live-tickets-378070718697?utm_source=eventbrite&utm_medium=email&utm_campaign=post_publish&utm_content=shortLinkNewEmail">Moms on Maternity MATCH LIVE Event</a></ListGroup.Item>*/}



          {/*<span className="verticalLine"> | </span>*/}

          {/*<ListGroup.Item className="listGroupItem">*/}
          {/*  <a className="listGroupItem"*/}
          {/*     href="https://www.eventbrite.com/e/mindfulness-yoga-and-creativity-for-families-tickets-164783043519">Events*/}
          {/*  </a>*/}
          {/*</ListGroup.Item>*/}

        </ListGroup>
      </Row>

    </div>
  </header>
    <div className="headerNavMenu">
      <Row className="justify-content-center headerContainer">
        <Col className="mt-3">
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          ><Logo /></Link>
        </Col>
        <Col sm={{ span: 9, offset: 1 }} xs={6} className="headerContent">
          <MainNav />
        </Col>
      </Row>
    </div>
  </>

);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header
