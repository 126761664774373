import React from "react"

import styles from "./navbar.module.css"
import {graphql, useStaticQuery} from "gatsby";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";

const useStateWithLocalStorageForEmail = localStorageKey => {
  // localStorage.clear();

  //get email value from localStorage
  const [emailValueFromLocalStorage, setEmailValueFromLocalStorage] = React.useState(
    typeof window !== "undefined" && localStorage.getItem(localStorageKey) || '',
  );

  //set user email value in localStorage
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, emailValueFromLocalStorage);
  }, [emailValueFromLocalStorage]);

  return [emailValueFromLocalStorage, setEmailValueFromLocalStorage];
};

const useStateWithLocalStorageForLast4 = localStorageKey => {
  // localStorage.clear();

  const [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage] = React.useState(
    typeof window !== "undefined" && localStorage.getItem(localStorageKey) || '',
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, last4ValueFromLocalStorage);
  }, [last4ValueFromLocalStorage]);

  return [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage];
};

const MainNav = ({ children }) => {

  const [emailValueFromLocalStorage, setEmailValueFromLocalStorage] = useStateWithLocalStorageForEmail('email');
  const [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage] = useStateWithLocalStorageForLast4('last4');

  const data = useStaticQuery(graphql`
    query SiteNavbarQuery {
      site {
        siteMetadata {
          menuLinks {
            title
            link
            footerOnly
            children {
              link
              title
            }
          }
        }
      }
    }
  `);

  // const becomeAMemberNav = data.site.siteMetadata.menuLinks.filter(item => item.title === "Members");
  //
  // if(emailValueFromLocalStorage && last4ValueFromLocalStorage) {
  //   let logoutFound = false;
  //
  //   becomeAMemberNav[0].children.map((item, i) => {
  //     if (item.title === "Logout") {
  //       logoutFound = true;
  //     }
  //
  //     if(item.title === "Join Us") {
  //       delete becomeAMemberNav[0].children[i];
  //     }
  //   });
  //
  //   if (!logoutFound) {
  //     becomeAMemberNav[0].children.push({
  //       title: "Logout",
  //       link: "/logout",
  //     });
  //   }
  // }
  //
  // if(!emailValueFromLocalStorage && !last4ValueFromLocalStorage) {
  //   becomeAMemberNav[0].children.map(item => {
  //     if(item.title === "Member Lounge") {
  //       item.link = '/become-a-member';
  //     }
  //   });
  // }

  const menuLinks = data.site.siteMetadata.menuLinks.filter(item => !item.footerOnly);

    return (
    <Navbar expand="lg" className={styles.navbar}>
      <Navbar.Toggle aria-controls={styles.basicNavbarNav} />
      <Navbar.Collapse id={styles.basicNavbarNav} >
        <Nav className="mr-auto navButton">
          {menuLinks.map(item => (
            <div key={item.title}>
            {
              ((!item.children || !item.children.length) && !item.footerOnly) &&
                <Nav.Link className={styles.red} href={item.link}>{item.title}</Nav.Link>
            }
            {
              item.children && item.children.length &&
                <NavDropdown className={styles.red} href={item.link} title={item.title} id="basic-nav-dropdown" className={styles.dropdownMenu}>
                  {item.children.map(child => (
                    <NavDropdown.Item className={styles.dropdownItem} key={child.title} href={child.link}>{child.title}</NavDropdown.Item>
                  ))}
                </NavDropdown>
            }
            </div>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
};

export default MainNav;
