/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import env from "../config/env";

function SEO({ description, lang, meta, title, path, link, scripts, structuredData, image }) {
  const { site, logo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        logo: file(relativePath: { eq: "logo-new.jpg" }) {
          publicURL
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `google-site-verification`,
          content: 'vhdPxigLMBf6J-IEt_D2eTeq-PLsYXe8YTmGJWe9lMk',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image || env.domain + logo.publicURL,
        },
        {
          property: `og:locale`,
          content: `en_US`,
        },
        {
          property: `og:url`,
          content: path ? env.domain + path : env.domain,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: image || env.domain + logo.publicURL,
        },
        {
          name: `fb:app_id`,
          content: "263215610792219",
        },
      ].concat(meta)}
      link={[].concat(link)}
    >
      {scripts.map(script => (
        <script key={script.id} type={script.type} src={script.src}></script>
      ))}
      {structuredData ? <script type="application/ld+json">{JSON.stringify(structuredData)}</script> : "" }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en_us`,
  meta: [],
  description: ``,
  link: [
    {
      rel: 'stylesheet',
      href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      integrity: 'sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm',
      crossorigin: 'anonymous',
    },
    {
      rel: "stylesheet",
      href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css'
    }
  ],
  scripts: [
    {
      id: "vimeo",
      type: "text/javascript",
      src: "https://player.vimeo.com/api/player.js",
    },
  ],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  structuredData: PropTypes.object,
};

export default SEO
